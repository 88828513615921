import { gql } from '@apollo/client';
import { IUser } from 'src/interface/IUser';

import { IBrandEcommProduct } from '../../interface/IBrandEcommProduct';
import { IPublicBrand } from '../../interface/IPublicBrand';
import {
  ICollectibleMetadata,
  IInteractivity,
  IRepcardConsolidatedParticipation,
  RepCardParticipationStatusType,
  RepcardStatusTypes,
  RepCardTypes,
  RewardTypes,
} from '../../types';

export enum BrandRepCardEnum {
  DEFAULT = 'DEFAULT',
  REPEATABLE = 'REPEATABLE',
  AVAILABLE = 'AVAILABLE',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
  VIEWABLE = 'VIEWABLE',
}

export interface BrandPageRepCard {
  id: number;
  uuid: string;
  prompt: string;
  type: RepCardTypes;
  coverImageUrl: string;
  participationCount: number;
  repeatParticipations?: boolean;
  hideCollectibleResponses?: boolean;
  membershipCollectible: {
    uuid: string;
    contractAddress: string;
    tokenId?: string;
    quantity: string;
    createdAt: string;
    metadata: ICollectibleMetadata;
  };
  reward: {
    type: RewardTypes;
    reps: number;
    assetId?: string;
    quantity?: number;
    contractAddress?: string;
  };
  interactivity: IInteractivity;
  timeLimit: string;
  status: RepcardStatusTypes;
  participantUsersHighlight: IUser[];
  maxParticipations: number;
  myConsolidatedParticipation?: IRepcardConsolidatedParticipation;
}

export interface IRepCardParticipation {
  id: number;
  user: {
    avatarUrl?: string;
  };
}

export interface BrandPageData {
  brandBySlug?: IPublicBrand;
}

export interface BrandPageVars {
  urlSlug: string;
}

export interface IBrandAvailableRepCardsVars {
  data: {
    brandUuid: string;
    repCardId?: number;
    type?: BrandRepCardEnum;
    take: number;
    skip: number;
  };
}

export interface IBrandAvailableRepCardsData {
  getBrandAvailableRepCards: BrandPageRepCard[];
}

export const BRAND_PUBLIC_PAGE = gql`
  query BrandBySlug($urlSlug: String!) {
    brandBySlug(urlSlug: $urlSlug) {
      id
      uuid
      name
      logoUrl
      urlSlug
      avatarUrl
      heroImageUrl
      minParticipationAge
      description
      instagramUrl
      twitterUrl
      tiktokUrl
      websiteUrl
      isMember
      brandMembersCount: memberCount
      hasMainOnboarding
      membershipCollectible {
        uuid
        contractAddress
        tokenId
        quantity
        createdAt
        metadata {
          name
          description
          image
          animationUrl
        }
      }
      avatarRow(take: 11) {
        userId
        user {
          avatarUrl
        }
      }
      brandWallet {
        address
        collectiblesSettings {
          contractAddress
          tokenId
          visibility
        }
        collections {
          account
          contractAddress
          type
          baseCollectibleMetadata {
            name
            description
            image
            animationUrl
          }
          collectibleExtraMetadatas {
            contractAddress
            tokenId
            description
            additionalDetails
          }
        }
      }
    }
  }
`;

export const BRAND_AVAILABLE_REPCARDS = gql`
  query GetBrandAvailableRepCards($data: GetBrandAvailableRepCardsInput!) {
    getBrandAvailableRepCards(data: $data) {
      id
      uuid
      prompt
      type
      coverImageUrl
      repeatParticipations
      reward {
        type
        reps
        assetId
        contractAddress
        quantity
      }
      interactivity {
        totalComments
        totalHi5s
        uuid
      }
      timeLimit
      status
      participantUsersHighlight {
        id
        avatarUrl
      }
      participationCount
      maxParticipations
      hideCollectibleResponses
      maxParticipations
      myConsolidatedParticipation {
        status
        hasBeenApproved
        hasBeenRejected
        total
        totalPending
        totalRewarded
      }
    }
  }
`;
