import 'react-image-crop/dist/ReactCrop.css';

import { OAuthRedirectResult } from '@magic-ext/oauth';
import { Alert, Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';
import useModal, { ModalKey } from 'src/hooks/useModal';
import UnlockRewardModalContent from 'src/modals/UnlockRewardModalContent/UnlockRewardModalContent';

import ImageCropper from '../../components/ImageCropper';
import InputV2 from '../../components/InputV2/InputV2';
import useAuth from '../../hooks/useAuth';
// import useFullStory from '../../hooks/useFullStory';
import ConfirmationEmailNotificationModal from '../../modals/ConfirmationEmailNotificationModal/ConfirmationEmailNotificationModal';
import EmailAlreadyInUseModal from '../../modals/EmailAlreadyInUseModal/EmailAlreadyInUseModal';
import { AuthType, RewardTypes } from '../../types';
import { EMAIL_PATTERN } from '../../utils/validateEmail';

export type Inputs = {
  username: string;
  email?: string;
  avatar?: string;
  phoneNumber?: string;
};

export type InputsSubmitHandler = SubmitHandler<Inputs>;

export interface RegisterUsernameFormProps {
  defaultValues?: DefaultValues<Inputs>;
  authType: AuthType;
  email?: string;
  phoneNumber?: string;
  onChangeStep: (email: string) => void;
  oauthRedirectResult?: OAuthRedirectResult;
}

const RegisterUsernameForm: React.FC<RegisterUsernameFormProps> = ({
  defaultValues,
  authType,
  email,
  phoneNumber,
  onChangeStep,
  oauthRedirectResult,
}) => {
  // const FS = useFullStory();

  const auth = useAuth();
  const router = useRouter();
  const { open: openModal, close: closeModal } = useModal();

  const { brand } = router.query as Record<string, string>;
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>(defaultValues.avatar);
  const [showEmailAlreadyInUseModal, setShowEmailAlreadyInUseModal] = useState<boolean>(false);
  const [emailAlreadyInUseModalMessage, setEmailAlreadyInUseModalMessage] = useState<string>('');
  const [confirmationEmailNotificationModal, setConfirmationEmailNotificationModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Inputs>({ defaultValues });

  const onSubmit = useCallback(
    async (formData) => {
      try {
        setLoadingSubmit(true);

        const validate = await auth.registerValidate({
          authType,
          phoneNumber,
          email: email || formData?.email,
          username: formData?.username,
          avatar: formData?.avatar,
          brandSlug: brand,
        });

        if (validate.validateRegisterUser.emailHasExistingUser) {
          setConfirmationEmailNotificationModal(true);
          setLoadingSubmit(false);
          return;
        }

        const { joinedBrands } = await auth.register({
          authType,
          phoneNumber,
          email: email || formData?.email,
          username: formData?.username,
          avatar: formData?.avatar,
          brandSlug: brand,
        });

        const joinedBrand = joinedBrands.find((v) => v.urlSlug == brand);

        console.log('RegisterUsername form onSubmit');

        openModal(
          ModalKey.EARN_COLLECTIBLE_MEMBERSHIP,
          <Flex width="800px">
            <UnlockRewardModalContent
              rewardMetadata={joinedBrand.membershipCollectible.metadata}
              reward={{ ...joinedBrand.membershipCollectible, type: 'COLLECTIBLE' as RewardTypes }}
              onContinue={() => {
                closeModal(ModalKey.EARN_COLLECTIBLE_MEMBERSHIP);
              }}
            />
          </Flex>
        );

        // FS.event('Profile Submit', {
        //   email: email,
        // });

        setLoadingSubmit(false);
      } catch (error) {
        console.log('error submitting...');
        setLoadingSubmit(false);

        if (error.graphQLErrors?.find((ge) => ge.extensions?.exception?.response?.showEmailAlreadyInUseModal)) {
          setShowEmailAlreadyInUseModal(true);
          setEmailAlreadyInUseModalMessage(error.message);
          return;
        }
      }
    },
    [auth, authType, email, phoneNumber]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '80%' }}>
      <Box mb="3">
        <Text variant="text3-400" textAlign="center">
          Let’s set up your profile
        </Text>
      </Box>
      <Box mb="3">
        <Text variant="text4-400">You&apos;re signing up with:</Text>
        {authType === 'SMS' && (
          <ReactPhoneInput
            containerStyle={{
              display: 'flex',
              flexDirection: 'row-reverse',
              width: '100%',
              height: '48px',
            }}
            inputStyle={{
              border: 'none',
              borderRadius: 'unset',
              paddingLeft: '20px',
              paddingRight: '20px',
              height: '52px',
              backgroundColor: '#f5f5f5',
              width: '100%',
            }}
            buttonStyle={{
              position: 'unset',
              border: 'none',
              width: '52px',
              height: '52px',
              pointerEvents: 'none',
            }}
            disableDropdown
            disabled
            value={defaultValues.phoneNumber}
          />
        )}
        {authType === 'EMAIL' && <InputV2 name="email" disabled value={defaultValues.email} />}
        {authType === 'SOCIAL' && (
          <InputV2 name="email" disabled value={oauthRedirectResult.magic.userMetadata.email} />
        )}
      </Box>

      <Box mb="3">
        <Text variant="text4-400">Choose username:</Text>
        <Text variant="text6-400" color="#BEBABA" mb="1">
          This name will appear in your wallet and community.
        </Text>
        <InputV2 placeholder="Enter your username" {...register('username', { required: true })} />
        {errors.username && <Alert type="error">{errors.username.message || 'This field is required'}</Alert>}
      </Box>

      {authType === 'SMS' && (
        <>
          <Text variant="text4-400">Email:</Text>
          <Text variant="text6-400" color="#BEBABA" mb="1">{`We'll use this for notifications.`}</Text>
          <Box mb="3">
            <InputV2
              placeholder="Enter your email"
              {...register('email', {
                required: true,
                pattern: {
                  value: EMAIL_PATTERN,
                  message: 'Enter a valid e-mail address',
                },
              })}
            />
            {errors.email && <Alert type="error">{errors.email.message || 'This field is required'}</Alert>}
          </Box>
        </>
      )}

      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        justifyItems="center"
      >
        <Text variant="text3-600" fontSize="18" fontWeight="bold" mb="2">
          Upload profile picture
        </Text>
        <Flex mb="4" width="100%" data-testid="user-registration-upload-profile-picture">
          <ImageCropper
            src={avatar}
            maxFileSize={50}
            onChange={(src) => {
              setValue('avatar', src);
              setAvatar(src);
            }}
            onChangeLoading={(loading) => {
              setLoadingAvatar(loading);
            }}
          />
        </Flex>
      </Flex>

      <Flex flexDirection="column" mt="2" pb="2">
        <Button type="submit" scale="lg" fontSize="22px" px="8" disabled={loadingSubmit || loadingAvatar}>
          {!loadingSubmit ? 'Continue' : 'Loading...'}
        </Button>
      </Flex>

      <ConfirmationEmailNotificationModal
        isOpen={confirmationEmailNotificationModal}
        onClose={() => setConfirmationEmailNotificationModal(false)}
        onConfirmThatsMe={() => {
          setConfirmationEmailNotificationModal(false);
          onChangeStep(getValues('email'));
        }}
        onConfirmThatsNotMe={() => {
          setConfirmationEmailNotificationModal(false);
        }}
      />

      <EmailAlreadyInUseModal
        isOpen={showEmailAlreadyInUseModal}
        onClose={() => {
          setShowEmailAlreadyInUseModal(false);
          setEmailAlreadyInUseModalMessage('');
        }}
        message={emailAlreadyInUseModalMessage}
      />
    </form>
  );
};

export default RegisterUsernameForm;
