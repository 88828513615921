import { useCallback, useEffect, useMemo } from 'react';
import { IBrand } from 'src/interface/IBrand';

import useAuth from '../../hooks/useAuth';
import useWalletActivity from '../../hooks/useWalletActivity';
import { IWallletActivity } from '../../interface/IWallletActivity';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setActivity } from '../../redux/user/userSlice';
import PusherConsumer from '../PusherConsumer';

const MagicAuthProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const activity = useWalletActivity();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    auth.loadActiveSession();
  }, []);

  useMemo(() => {
    if (user.cChainPublicAddress) {
      (async () => {
        const result = await activity.fetch(user.cChainPublicAddress);
        if (result) {
          dispatch(setActivity(result.data?.walletActivity?.filter((v) => v.type === 'TRANSFER') || []));
        }
      })();
    }
  }, [user.cChainPublicAddress]);

  const onNewActivity = (newActivity: IWallletActivity) => {
    if (newActivity.type === 'TRANSFER') {
      activity.onNewActivity(newActivity);
      auth.fetchWallet();
    }
  };

  const onNewBrandMember = useCallback(() => {
    auth.loadActiveSession();
  }, [auth]);

  return (
    <>
      {/* TODO: maybe it's an overkill to refetch wallet for every new activity this may be redundant with the new brend member event */}
      <PusherConsumer event="new.activity" onData={onNewActivity} />
      <PusherConsumer event="update.activity" onData={activity.onUpdateActivity} />
      <PusherConsumer event="new.brand_member" onData={onNewBrandMember} />

      {children}
    </>
  );
};

export default MagicAuthProvider;
